// helper functions

// a helper function to make capital, monospace strings
const toAnt = (str, space=' ', replacementCallback = str => str) => {
    // master function to use to 'translate' into ant text
    // str: the input string
    // space: the char to delimit text
    // 

    // split the string into individual words
    const words = str.toUpperCase().split(/\s/);

    // compose the translated string
    return words.slice(1).reduce((newString, word) =>{
        word = replacementCallback(word);
        return newString + space + word;
    }, replacementCallback(words[0]));
}

export { toAnt };