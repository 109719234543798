import React from 'react';
import ReactDOM from 'react-dom';
import { SocialMediaIconsReact } from 'social-media-icons-react';
import { toAnt } from './functions';

export default () => {
    return (<div className="footer-link">
        <h3>{ toAnt('links') }</h3>
        <ul>
            <li><SocialMediaIconsReact borderColor="rgba(0,0,0,0)" borderWidth="5" borderStyle="solid" icon="twitter" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(255,51,51,1)" iconSize="5" roundness="50%" url="https://twitter.com/hermetikosmedia" size="45" /></li>
            <li><SocialMediaIconsReact borderColor="rgba(0,0,0,0)" borderWidth="5" borderStyle="solid" icon="instagram" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(255,51,51,1)" iconSize="5" roundness="50%" url="https://instagram.com/stan_the_freelancer?igshid=a2r2ytdwa2ys" size="45" /></li>
            <li><SocialMediaIconsReact borderColor="rgba(0,0,0,0)" borderWidth="5" borderStyle="solid" icon="web" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(255,51,51,1)" iconSize="5" roundness="50%" url="https://www.hermetikos.com/" size="45" /></li>
        </ul>
    </div>);
}