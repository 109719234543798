import React from 'react';
// import logo
import Logo from '../assets/ant-logo.svg';
import { toAnt } from './functions';

export default () => {
    return (
    <header className="header">
        <h1 className="small-item">{ toAnt('trantslator') }</h1>
        <Logo className="large-item"/>
    </header>
    );
}