import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { randomBytes } from 'crypto';

import { toAnt } from './functions';

function SEO({ lang, meta, title, pathname }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
            title
            image
						description
						author
						keywords
            siteUrl
            twitterUsername
            favicons {
              android {
                  small
                  large
              }
              apple
              generic {
                  small
                  large
                  fav
              }
              manifest
          }
				}
      }
    }`
	)

	const metaDescription = site.siteMetadata.description;
	// const image =
  //   metaImage && metaImage.src
  //     ? `${site.siteMetadata.siteUrl}${metaImage.src}`
  //     : null
  const image = `${site.siteMetadata.siteUrl}${site.siteMetadata.image}?${randomBytes(16).toString()}`;
  // favicon data
  const favicons = site.siteMetadata.favicons;
  const faviconApple = `${site.siteMetadata.siteUrl}${favicons.apple}`;
  const faviconGenericSmall = `${site.siteMetadata.siteUrl}${favicons.generic.small}`;
  const faviconGenericLarge = `${site.siteMetadata.siteUrl}${favicons.generic.large}`;
  const faviconManifest =`${site.siteMetadata.siteUrl}${favicons.manifest}`;
  
  // the canonical URL for the page
  // an authoritative URL that search engines like
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null;
  
  // the URL for the site
  const siteUrl = site.siteMetadata.siteUrl;
  // the twitter profile
  const twitterProfile = site.siteMetadata.twitterUsername;
  
	return (
		<Helmet
			htmlAttributes={{
				lang,
      }}
      title={ toAnt(title) }
      titleTemplate={ `${ toAnt(site.siteMetadata.title) } | %s`}
      link={[
        canonical
          ? [
            {
              rel: 'canonical',
              href: canonical
            },
          ]
        : {},
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: faviconApple
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: faviconGenericSmall
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: faviconGenericLarge
        },
        {
          rel: 'manifest',
          href: faviconManifest
        }
      ]}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					name: "keywords",
					content: site.siteMetadata.keywords.join(','),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: 'og:url',
          content: siteUrl
        },
        {
          property: `og:type`,
          content: 'website',
        },
        {
          property: 'og:image',
          content: image,
        },
        {
          property: 'og:image:width',
          content: '1200',
        },
        {
          property: 'og:image:height',
          content: '630',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:site',
          content: twitterProfile,
        },
        {
          name: 'twitter:creator',
          content: twitterProfile,
        },
        {
          name: 'twitter:image',
          content: image
        },
        {
          name: 'twitter:image:src',
          content: image
        },
        {
          property: 'twitter:image:width',
          content: '1200',
        },
        {
          property: 'twitter:image:height',
          content: '630',
        },
      ].concat(meta) }
		/>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
  }),
  pathname: PropTypes.string,
}

export default SEO;