import React from 'react';
import { useStaticQuery, graphql, Link } from "gatsby";

import Social from './social';
import { toAnt } from './functions';

export default () => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        pages
                    }
                }
            }
        `
    );
    
    const pages = site.siteMetadata.pages;

    return ( 
    <footer className="footer">
        <hr />
        <div className="footer-link">
            <h3 className="link-margin">{ toAnt('pages') }</h3>
            <ul>
                { pages.map(page => {
                    return (
                        <li key={ `${page}_link_key` }>
                            <Link to={ `/${page}/`} className="small-item internal-link" >
                            <h2>{ page ? toAnt(page) : 'HOME' }</h2>
                            </Link>
                        </li>
                    );
                })}	
            </ul>
        </div>
        <hr />        
        <Social />
    </footer>
    );
}